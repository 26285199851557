<template>
  <button
    v-show="!isGlobalLoading && !isLoading"
    @click="modalShowLocation = true"
    class="choose-region__button"
    id="choose-region-button"
  >
    <span>{{ currentRegion || "Москва и область" }}</span>
  </button>
  <Teleport to="body">
    <RegionConfirmModal
      v-show="
        !region && !modalShowRegionConfirm && !isGlobalLoading && !isLoading
      "
      @close="onCloseModalRegionConfirm"
      @changeRegion="openLocationModal"
    />
    <ModalLocation
      :is-show="modalShowLocation"
      @close="onCloseLocation"
      :current="currentRegion"
    />
  </Teleport>
</template>

<script>
import ModalLocation from "@/components/ModalLocation.vue";
import RegionConfirmModal from "@/components/RegionConfirmModal.vue";
import { regions } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "ChooseRegion",
  components: {
    ModalLocation,
    RegionConfirmModal,
  },
  data() {
    return {
      modalShowLocation: false,
      modalShowRegionConfirm: false,
      showRegionConfirm: true,
      regions,
    };
  },
  computed: {
    ...mapState({
      regionConfirm: (state) => state.api.regionConfirm,
      isLoading: (state) => state.api.isLoading,
      isGlobalLoading: (state) => state.api.isGlobalLoading,
    }),
    currentRegion() {
      return this.regions[this.region]?.name || this.regionConfirm?.name;
    },
    region() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      return urlParams.get("region") || this.$route.query.region;
    },
  },
  methods: {
    onCloseLocation() {
      this.modalShowLocation = false;
    },
    onCloseModalRegionConfirm() {
      this.modalShowRegionConfirm = true;
    },
    openLocationModal() {
      this.modalShowRegionConfirm = true;
      this.modalShowLocation = true;
    },
  },
};
</script>

<style scoped lang="scss">
.choose-region {
  &__button {
    @include reset-button;
    font-size: 12px;
    line-height: 1;
    color: var(--colorWhite);
    width: 100%;
    text-align: left;
    padding-left: 36px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='32' fill='none'%3E%3Cpath fill='%23fff' d='M15.835 28.686c.216.209.5.314.782.314.282 0 .566-.105.782-.314l.004-.004c.426-.412 9.214-8.902 9.214-15.833 0-6.7-5.184-9.849-10-9.849s-10 3.15-10 9.848c0 6.932 8.788 15.422 9.214 15.834l.004.004Z'/%3E%3Ccircle cx='16.617' cy='12' r='4' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    margin-left: 8px;
    min-height: 32px;
    @include md {
      margin-left: 16px;
    }
  }
}
</style>
