<template>
  <SectionLayout color="white">
    <template #body>
      <PromoForeigner id="promo-foreigner" />
      <SectionTariffs id="tariffs" />
      <DetailOptions id="home-tariffs" />
    </template>
  </SectionLayout>
  <SectionLayout id="foreigner" color="dark">
    <template #head>
      <h1>Как подключить <br />SIM-карту иностранному <br />гражданину</h1>
      <p>Указанная информация действительна с 01.01.2025.</p>
    </template>
    <template #body>
      <SectionForeigner />
    </template>
  </SectionLayout>
</template>

<script setup>
import DetailOptions from "@/components/DetailOptions.vue";
import SectionLayout from "@/components/SectionLayout.vue";
import SectionTariffs from "@/components/SectionTariffs.vue";
import SectionForeigner from "@/components/SectionForeigner.vue";
import PromoForeigner from "@/components/PromoForeigner.vue";
import useLanguage from "@/hooks/useLanguage";
import useTranslate from "@/hooks/useTranslate";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";

const { getLanguage } = useLanguage();
const { translate } = useTranslate();
const store = useStore();

const updateLanguage = async () => {
  await Promise.all([
    translate("#promo-foreigner", document, "promo-foreigner"),
    translate("#tariffs", document, "home"),
    translate("#home-tariffs", document, "frame-tariffs"),
    translate("#foreigner", document, "foreigner"),

    translate("#choose-region-button", document, "choose-region-button"),
    translate("#region-confirm", document, "region-confirm"),
    translate("#modal-location", document, "modal-location"),
  ]);
};

onMounted(async () => {
  store.commit("setProp", { isLoading: true });
  if (getLanguage() !== "ru") {
    await updateLanguage();
  }
  store.commit("setProp", { isLoading: false });
});

watch(getLanguage, async () => {
  store.commit("setProp", { isLoading: true });
  await updateLanguage();
  store.commit("setProp", { isLoading: false });
});
</script>
