export const appTranslations = {
  loading: {
    ru: "Загрузка...",
    ky: "Жүктөө",
    tg: "Боркунӣ",
    uz: "Yuklash",
    zh: "加载中...",
    en: "Loading...",
    es: "Cargando...",
  },
};
