export const footerTranslations = {
  copyright: {
    ru: "© t2 Россия,",
    ky: "© t2 Орусия,",
    tg: "© t2 Русия,",
    uz: "© t2 Rossiya,",
    zh: "© t2 俄罗斯,",
    en: "© t2 Russia,",
    es: "© t2 Rusia,",
  },
  info: {
    ru: "Подробная информация на сайте t2.ru",
    ky: "Толук маалымат сайтта t2.ru",
    tg: "Маълумоти муфассал дар сайт t2.ru",
    uz: "Batafsil ma'lumot t2.ru saytida",
    zh: "网站 t2.ru 上的详细信息",
    en: "Detailed information on the t2.ru",
    es: "Información detallada en el sitio web t2.ru",
  },
};
