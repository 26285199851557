import { computed } from "vue";
import useLanguage from "./useLanguage";

export const useTranslations = (translations) => {
  const { getLanguage } = useLanguage();

  const t = (key) => {
    const lang = getLanguage();
    if (!translations[key]) {
      console.warn(`Translation key "${key}" not found`);
      return key;
    }
    return translations[key][lang] || translations[key].ru;
  };

  return {
    t: computed(() => t),
  };
};
