export const languageTranslations = {
  selectLanguage: {
    ru: "Выберите язык",
    ky: "Тилди тандаңыз",
    tg: "Забонро интихоб кунед",
    uz: "Tilni tanlang",
    zh: "选择语言",
    en: "Select language",
    es: "Seleccionar idioma",
  },
  language: {
    ru: "Язык",
    ky: "Тил",
    tg: "Забон",
    uz: "Til",
    zh: "语言",
    en: "Language",
    es: "Idioma",
  },
};
